import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Portfolio.css";
import PrimaryButton from "../../Components/Atoms/Primary/PrimaryButton";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ServiceCard from "../../Components/Molecules/ServiceCard/ServiceCard";
import { Images } from "../../Helpers/Images";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import TextFieldInput from "../../Components/Atoms/TextField/TextFieldInput";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Projects, Services, Steps } from "../../Helpers/Arrays";
import ContactForm from "../../Components/Molecules/ContactForm/ContactForm";

const PortfolioTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "center" },
            flexDirection: { xs: "column-reverse", md: "row" },
            marginBottom: "20px",
          }}
        >
          <Box sx={{ maxWidth: "370px", marginRight: "10px" }}>
            <Typography
              variant={"h2"}
              sx={{
                fontWeight: "800",
                fontSize: { xs: "28px", md: "32px" },
                marginTop: { xs: "10px", md: "0" },
              }}
            >
              Delivering Excellence Through Every Project
            </Typography>
            <Typography
              variant={"p"}
              sx={{
                fontWeight: "200",
                fontSize: { xs: "15px", md: "15px" },
                width: "100%",
                maxWidth: "350px",
                marginTop: { xs: "10px", md: "20px" },
                display: "block"
              }}
            >
              Here, we showcase the diverse range of projects we've successfully completed for our clients. Each project highlights our commitment to excellence, innovation, and delivering tailored solutions that meet unique business needs.
            </Typography>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: "60px", marginTop: { xs: "10px", md: "20px" }, marginLeft: "-15px" }} />
          </Box>
          <img src={Images.Portfolio} alt="" style={{ width: "calc(100% - 30px)", maxWidth: "350px", boxShadow: "30px 0  #03624c", marginRight: "30px" }} />
        </Box>
        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "1400px",
            margin: "auto",
            alignItems: "flex-start",
          }}
        >
          {Projects?.map((pro, i) => (
            <Grid
              item
              sm={12}
              md={6}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: { xs: "flex-end" },
                  flexDirection: { xs: "column" },
                  padding: { xs: "0 0 20px", md: "15px" },
                  cursor: "pointer",
                }}
              >
                <img src={pro?.full} alt="" style={{ width: "100%" }} />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-end" },
                    flexDirection: { xs: "column", sm: "row" },
                  }}>
                  <Box>
                    <Typography
                      variant={"h2"}
                      sx={{
                        fontWeight: "800",
                        fontSize: { xs: "25px", md: "32px" },
                      }}
                    >
                      <span style={{ color: "var(--primary)", fontSize: "18px" }}>*{pro?.type}</span>
                      <br />
                      {pro?.title}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "200",
                        fontSize: { xs: "12px", md: "15px" },
                        width: "100%",
                        maxWidth: "600px",
                        marginTop: "5px",
                        display: "block"
                      }}
                    >
                      {pro?.desc}
                    </Typography>
                  </Box>
                  {/* <KeyboardDoubleArrowDownIcon sx={{ fontSize: "60px", marginLeft: "-15px" }} /> */}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 60px 50px", lg: "0 120px 50px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center", marginBottom: "30px",
          }}
        >
          <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Contact Us</span>
          <br />
          It's Very Easy To Contact Us
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "850px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", md: "400px" }, backgroundColor: "#03624c10", padding: { xs: "30px", md: "50px" }, marginBottom: { xs: "20px", md: "0" } }}>
              <ContactForm />
            </Box>
            <Box sx={{
              width: "100%", maxWidth: "350px", margin: "0 20px"
            }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <PhoneIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>+20 1559661620</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <EmailIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>pulsonic.company@gmail.com</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>Pulsonic Company</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PortfolioTemplate;
