import React, { useEffect } from "react";
import ServicesTemplate from "../../Templates/Services/Services.template";

const ServicesPage = () => {
  useEffect(() => {
    document.title = "Services - Pulsonic";
  }, []);
  return <ServicesTemplate />;
};

export default ServicesPage;
