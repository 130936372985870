import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Services.css";
import PrimaryButton from "../../Components/Atoms/Primary/PrimaryButton";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ServiceCard from "../../Components/Molecules/ServiceCard/ServiceCard";
import { Images } from "../../Helpers/Images";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import TextFieldInput from "../../Components/Atoms/TextField/TextFieldInput";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQs, Services, Steps } from "../../Helpers/Arrays";

const ServicesTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "center" },
            flexDirection: { xs: "column-reverse", md: "row" },
            marginBottom: "20px",
          }}
        >
          <Box sx={{ maxWidth: "370px", marginRight: "10px" }}>
            <Typography
              variant={"h2"}
              sx={{
                fontWeight: "800",
                fontSize: { xs: "28px", md: "32px" },
                marginTop: { xs: "10px", md: "0" },
              }}
            >
              Providing The <span style={{ color: "#03624c", textDecoration: "underline" }}>Best Service</span> For Our Customers
            </Typography>
            <Typography
              variant={"p"}
              sx={{
                fontWeight: "200",
                fontSize: { xs: "15px", md: "15px" },
                width: "100%",
                maxWidth: "350px",
                marginTop: { xs: "10px", md: "20px" },
                display: "block"
              }}
            >
              We offer some special sevices through which you can easliy complete the marketing work on social media. Which helps increase your lead and sales.
            </Typography>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: "60px", marginTop: { xs: "10px", md: "20px" }, marginLeft: "-15px" }} />
          </Box>
          <img src={Images.Services} alt="" style={{ width: "calc(100% - 30px)", maxWidth: "350px", boxShadow: "30px 0  #03624c", marginRight: "30px" }} />
        </Box>

        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "920px",
            margin: "auto",
            alignItems: "flex-start",
          }}
        >
          {Services?.map((ser) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <ServiceCard hover={false} title={ser?.title} back={ser?.back} desc={ser?.desc} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{
        background: `linear-gradient(75deg, #03624c60,#00000090, #942D2D60), #000000ee`
      }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "20px 20px 50px", sm: "50px 60px 80px", lg: "50px 120px 80px" },
            maxWidth: "1400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              color: "white",
              fontSize: { xs: "25px", md: "50px" },
              textAlign: "center"
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Working Process</span>
            <br />
            Let's See How We Work!
          </Typography>
          <Grid
            container
            style={{
              justifyContent: "center",
              marginTop: "20px",
              maxWidth: "920px",
              margin: "auto",
              alignItems: "flex-start",
            }}
          >
            {Steps?.map((ser, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Box sx={{ position: "relative" }}>
                  <span style={{ fontSize: "80px", fontWeight: "800", position: "absolute", top: "-40px", left: "15px" }} className="grText1">0{i + 1}</span>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontWeight: "800",
                      color: "white",
                      fontSize: { xs: "17px", md: "20px" },
                      marginTop: "40px",
                      position: "relative",
                      left: "40px",
                      maxWidth: "80%",
                    }}
                  >
                    {ser?.title}
                  </Typography>
                  <Typography
                    variant={"p"}
                    sx={{
                      fontWeight: "200",
                      color: "#ffffffcc",
                      fontSize: { xs: "12px", md: "13px" },
                      position: "relative",
                      left: "40px",
                      display: "block",
                      maxWidth: "80%", marginTop: "8px"
                    }}
                  >
                    {ser?.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column" },
          }}>
            <Box sx={{
              width: "100%"
            }}>
              <Typography
                variant={"h2"}
                sx={{
                  fontWeight: "600",
                  color: "black",
                  fontSize: { xs: "25px", md: "40px" },
                  textAlign: "start", marginBottom: "10px",
                }}
              >
                <span style={{ color: "var(--primary)", fontSize: "20px" }}>*FAQ</span>
                <br />
                Most Asked Questions
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "200",
                  fontSize: { xs: "12px", md: "15px" },
                  display: "block",
                  marginBottom: "20px"
                }}
              >
                Have more questions? Feel free to reach out to us directly, and our team will be happy to assist you!
              </Typography>
            </Box>
            <Box sx={{ width: "100%", backgroundColor: "#03624c10", padding: { xs: "20px", sm: "50px" } }}>
              {FAQs?.map((item) => (
                <Accordion sx={{
                  backgroundColor: "#03624c02", boxShadow: "0", borderBottom: "2px solid var(--primary)", borderTop: "0", borderRadius: "0 !important",
                  '&:before': {
                    height: "0",
                  }
                }}>
                  <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    َ{item?.question}
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.answer}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ServicesTemplate;
