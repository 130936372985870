import React, { useEffect } from "react";
import BlogDetailsTemplate from "../../Templates/Blog/BlogDetails.template";
import { useParams } from "react-router-dom";
import { Blogs } from "../../Helpers/Arrays";

const BlogDetailsPage = () => {
  const { id } = useParams()
  useEffect(() => {
    document.title = `${Blogs?.find((blog) => blog?.id == id)?.title} - Pulsonic`;
  }, [id]);
  return <BlogDetailsTemplate id={id} />;
};

export default BlogDetailsPage;
