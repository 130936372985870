import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Blog.css";
import { Blogs } from "../../Helpers/Arrays";
import TextFieldInput from "../../Components/Atoms/TextField/TextFieldInput";
import PrimaryButton from "../../Components/Atoms/Primary/PrimaryButton";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ContactForm from "../../Components/Molecules/ContactForm/ContactForm";

const BlogDetailsTemplate = ({ id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{
        background: `linear-gradient(75deg, #03624c60,#00000090, #942D2D60), #000000ee`
      }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "80px 40px", sm: "100px 50px", lg: "150px 50px" },
            maxWidth: "1400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxWidth: "900px",
              margin: "auto",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                fontWeight: "800",
                color: "white",
                fontSize: { xs: "38px", md: "50px" },
                textAlign: "center"
              }}
            >
              {Blogs?.find((blog) => blog?.id == id)?.title}
            </Typography>
            {/* <Typography
              variant={"p"}
              sx={{
                fontWeight: "200",
                fontSize: { xs: "15px", md: "18px" },
                width: "100%",
                marginTop: "10px",
                color: "white", textAlign: "center"
              }}
            >
              {Blogs?.find((blog) => blog?.id == 1)?.desc}
            </Typography> */}
          </Box>
        </Box>
      </Box >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
          }}
        >
          {Blogs?.find((blog) => blog?.id == id)?.content}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 60px 50px", lg: "0 120px 50px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center", marginBottom: "30px",
          }}
        >
          <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Contact Us</span>
          <br />
          It's Very Easy To Contact Us
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "850px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", md: "400px" }, backgroundColor: "#03624c10", padding: { xs: "30px", md: "50px" }, marginBottom: { xs: "20px", md: "0" } }}>
              <ContactForm />
            </Box>
            <Box sx={{
              width: "100%", maxWidth: "350px", margin: "0 20px"
            }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <PhoneIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>+20 1559661620</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <EmailIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>pulsonic.company@gmail.com</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>Pulsonic Company</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogDetailsTemplate;
