import React, { useEffect } from "react";
import ServicesTemplate from "../../Templates/Services/Services.template";
import PortfolioTemplate from "../../Templates/Portfolio/Portfolio.template";

const PortfolioPage = () => {
  useEffect(() => {
    document.title = "Portfolio - Pulsonic";
  }, []);
  return <PortfolioTemplate />;
};

export default PortfolioPage;
