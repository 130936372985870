import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Blog.css";
import { Blogs, FAQs } from "../../Helpers/Arrays";
import Blog from "../../Components/Molecules/Blog/Blog";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const BlogTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              fontSize: { xs: "25px", md: "40px" },
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Blog</span>
            <br />
            Our Latest Blogs
          </Typography>
        </Box>
        <Box>
          <Grid
            container
            style={{
              justifyContent: "center",
              marginTop: "20px",
              maxWidth: "900px",
              margin: "20px auto 0",
              alignItems: "flex-start",
            }}
          >
            {[...Blogs]?.reverse()?.map((ser) => (
              <Grid
                item
                xs={12}
              >
                <Blog title={ser?.title} back={ser?.back} desc={ser?.desc} type={ser?.type} id={ser?.id} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column" },
          }}>
            <Box sx={{
              width: "100%"
            }}>
              <Typography
                variant={"h2"}
                sx={{
                  fontWeight: "600",
                  color: "black",
                  fontSize: { xs: "25px", md: "40px" },
                  textAlign: "start", marginBottom: "10px",
                }}
              >
                <span style={{ color: "var(--primary)", fontSize: "20px" }}>*FAQ</span>
                <br />
                Most Asked Questions
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "200",
                  fontSize: { xs: "12px", md: "15px" },
                  display: "block",
                  marginBottom: "20px"
                }}
              >
                Have more questions? Feel free to reach out to us directly, and our team will be happy to assist you!
              </Typography>
            </Box>
            <Box sx={{ width: "100%", backgroundColor: "#03624c10", padding: { xs: "20px", sm: "50px" } }}>
              {FAQs?.map((item) => (
                <Accordion sx={{
                  backgroundColor: "#03624c02", boxShadow: "0", borderBottom: "2px solid var(--primary)", borderTop: "0", borderRadius: "0 !important",
                  '&:before': {
                    height: "0",
                  }
                }}>
                  <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    َ{item?.question}
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.answer}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogTemplate;
