import React, { useEffect } from "react";
import AboutTemplate from "../../Templates/About/About.template";

const AboutPage = () => {
  useEffect(() => {
    document.title = "About - Pulsonic";
  }, []);
  return <AboutTemplate />;
};

export default AboutPage;
