import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/system";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./Pages/Home/Home.page";
import NavBar from "./Components/Organisms/NavBar/NavBar";
import Footer from "./Components/Organisms/Footer/Footer";
import AboutPage from "./Pages/About/About.page";
import ContactPage from "./Pages/Contact/Contact.page";
import ServicesPage from "./Pages/Services/Services.page";
import PortfolioPage from "./Pages/Portfolio/Portfolio.page";
import BlogPage from "./Pages/Blog/Blog.page";
import BlogDetailsPage from "./Pages/Blog/BlogDetails.page";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#03624c",
      dark: "#000000",
    },
    secondary: {
      light: "#ffffff",
      main: "#f1f7f7",
      dark: "#000000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        <Box sx={{ width: "100%", flex: "1" }}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/services" element={<ServicesPage />} />
            <Route exact path="/portfolio" element={<PortfolioPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogDetailsPage />} />
          </Routes>
        </Box>
        <ToastContainer />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
