import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Images } from "../../../Helpers/Images";
import "./BlogCard.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const BlogCard = ({ desc, title, back, type, id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(75deg, rgba(0,0,0,0.7), rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${back})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          minHeight: { xs: "180px", sm: "260px" },
          margin: { xs: "10px 0", sm: "10px" },
          padding: { xs: "15px", sm: "30px" },
          cursor: "pointer",
          position: "relative"
        }}
        onClick={() => navigate(`/blog/${id}`)}
      >
        <p style={{ position: "absolute", top: "20px", right: "20px", backgroundColor: "var(--primary)", padding: "5px 10px", color: "white", fontSize: "13px" }}>{type}</p>

        <h3 style={{
          color: "white", textAlign: "start", fontSize: "18px", margin: "0px",
        }}>
          {title}
        </h3>
      </Box>
    </>
  );
};

export default BlogCard;
