import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../../../Helpers/Images";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useForm, ValidationError } from '@formspree/react';
import { toast } from "react-toastify";
import TextFieldInput from "../../Atoms/TextField/TextFieldInput";
import PrimaryButton from "../../Atoms/Primary/PrimaryButton";

const ContactForm = ({ }) => {
  const [state, handleSubmit] = useForm("xdknnnew");

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Thank you for contacting us! We have received your message and will get back to you as soon as possible.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: { backgroundColor: "#03624c" }
      });
    }

  }, [state]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextFieldInput
          name="name"
          type="text"
          placeholder={"Your Name"}
          className="Input"
          variant="standard"
          label="Name"
          style={{ padding: "5px 0" }}
        />
        <TextFieldInput
          name="name"
          type="text"
          placeholder={"Your Email"}
          className="Input"
          variant="standard"
          label="Email"
          style={{ padding: "5px 0" }}
        />
        <TextFieldInput
          name="name"
          btnType="text"
          placeholder={"Your Message"}
          className="Input"
          variant="standard"
          label="Message"
          style={{ padding: "5px 0" }}
        />
        <PrimaryButton
          type="submit"
          classBtn={"center"}
          disabled={state.submitting}
          text={<>Submit <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
          sx={{
            backgroundColor: "var(--primary)",
            color: "white",
            textTransform: "capitalize",
            borderRadius: "20px",
            padding: "7px 15px 7px 20px",
            width: "fit-content",
            marginTop: "10px",
            '&:hover': {
              backgroundColor: "var(--primary)",
            }
          }}
        />
      </form>
    </>
  );
};

export default ContactForm;
