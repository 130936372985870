import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../../../Helpers/Images";
import "./ServiceCard.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const ServiceCard = ({ desc, title, back, hover = true }) => {
  return (
    <>
      <Box
        sx={{
          background: false ? `linear-gradient(75deg, rgba(0,0,0,0.7), rgba(0,0,0,0.4), #942D2D30), url(${back})` : "#11141d",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          minHeight: { xs: "190px", sm: "220px" },
          padding: "30px",
          margin: { xs: "10px auto", sm: "10px" },
          cursor: hover ? "pointer" : "auto",
          maxWidth: "380px"
        }}
        className={hover ? "ServCard" : ""}
      >
        <h3 style={{ color: "white", textAlign: "start", fontSize: "20px" }}>
          {title}
        </h3>
        <p className="CardDesc" style={{ display: hover ? "none" : "block" }}>{desc}</p>
      </Box>
    </>
  );
};

export default ServiceCard;
