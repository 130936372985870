import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../../../Helpers/Images";
import "./ProjectCard.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const ProjectCard = ({ desc, title, back }) => {
  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(75deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${back})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          minHeight: "300px",
          minHeight: { xs: "190px", sm: "300px" },
          margin: { xs: "10px", sm: "10px" },
          padding: { xs: "15px", sm: "30px" },
          cursor: "pointer",
          color: "white",
        }}
      >
        <p style={{ fontSize: "15px", marginBottom: "5px", color: "#ffffffbb", textAlign: "start" }}>{desc}</p>
        <h3 style={{ textAlign: "start", fontSize: "20px" }}>
          {title}
        </h3>
      </Box>
    </>
  );
};

export default ProjectCard;
