import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../../../Helpers/Images";
import "./Blog.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PrimaryButton from "../../Atoms/Primary/PrimaryButton";

const Blog = ({ desc, title, back, id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // marginBottom: "20px",
          padding: { xs: "15px", sm: "20px" },
          borderTop: "2px solid var(--primary)",
          alignItems: "center", flexDirection: { xs: "column-reverse", sm: "row" }
        }}
      >
        <Box>
          <h3 style={{
            color: "black", textAlign: "start", fontSize: "25px",
          }}>
            {title}
          </h3>
          <Typography
            variant={"p"}
            className="blogDesc"
            sx={{
              fontWeight: "200",
              fontSize: { xs: "15px", md: "15px" },
              width: "100%",
              marginTop: "10px",
              maxWidth: "90%",
              height: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {desc}
          </Typography>
          <PrimaryButton
            classBtn={"center"}
            text={<>Read more <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              backgroundColor: "var(--primary)",
              color: "white",
              padding: "7px 15px 7px 23px",
              width: "fit-content",
              marginTop: "15px",
            }}
            click={() => navigate(`/blog/${id}`)}
          />
        </Box>
        <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "200px" }, flexShrink: "0" }}>
          <img src={back} alt="" style={{
            width: "100%",
            border: "2px solid var(--primary)"
          }} />
        </Box>
      </Box>
    </>
  );
};

export default Blog;
