/* -- -- -- Logo -- -- -- */

import Logo from "../Assets/Logo.png";
import BlackLogo from "../Assets/BlackLogo.png";
import WhiteLogo from "../Assets/WhiteLogo.png";
import LogoWithoutText from "../Assets/LogoWithoutText.png";
import Background from "../Assets/background.jpg";
import AboutBackground from "../Assets/about.jpg";
import Vision from "../Assets/vision.jpg";
import Services from "../Assets/services.jpg";
import Contact from "../Assets/contact.jpg";
import Blog2 from "../Assets/blog2.png";
import Blog1 from "../Assets/blog1.png";
import Blog3 from "../Assets/blog3.png";
import post1 from "../Assets/1.png";
import post2 from "../Assets/2.png";
import post3 from "../Assets/3.png";
import post4 from "../Assets/4.png";
import post5 from "../Assets/5.png";
import post6 from "../Assets/6.png";
import servHome from "../Assets/serv.jpg";
import ContBack from "../Assets/conta.jpg";
import Portfolio from "../Assets/portfolio.jpg";
import code from "../Assets/code.png";
import wedo from "../Assets/wedo.png";
import skill from "../Assets/skill.png";
import codeCover from "../Assets/codeCover.png";
import wedoCover from "../Assets/wedoCover.png";
import skillCover from "../Assets/skillCover.png";
import regCover from "../Assets/regCover.png";
import LMSCover from "../Assets/LMSCover.png";
import codeFull from "../Assets/covers/1.png";
import robotFull from "../Assets/covers/2.png";
import regFull from "../Assets/covers/3.png";
import wedoFull from "../Assets/covers/4.png";
import skillFull from "../Assets/covers/5.png";
import LMSFull from "../Assets/covers/6.png";

export const Images = {
  Blog1, Blog2, Blog3, skill, wedo, regCover, Portfolio, Logo, BlackLogo, WhiteLogo, LogoWithoutText, Background, post1, post2, post3, post4, post5, post6, servHome, ContBack, code, AboutBackground, Vision, Services, Contact, codeCover, wedoCover, skillCover, LMSCover, regFull, codeFull, wedoFull, robotFull, skillFull, LMSFull
};
