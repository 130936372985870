import { Typography } from "@mui/material";
import { Images } from "./Images";
import { Box } from "@mui/system";

export const Services = [
    {
        back: Images?.servHome,
        title: "Custom Software Development",
        desc: "Tailored software solutions designed to meet unique business needs, from concept to deployment.",
    },
    {
        back: Images?.servHome,
        title: "Web Application Development",
        desc: "Development of responsive, scalable, and secure web applications that enhance user experience.",
    },
    {
        back: Images?.servHome,
        title: "Mobile Application Development",
        desc: "Creation of intuitive and powerful mobile apps for both iOS and Android platforms.",
    },
    {
        back: Images?.servHome,
        title: "UI/UX Designs",
        desc: "Design of user-friendly interfaces and experiences that are both beautiful and functional.",
    },
    {
        back: Images?.servHome,
        title: "Maintenance and Support",
        desc: "Ongoing maintenance and support to ensure software remains up-to-date, secure, and efficient.",
    },
    {
        back: Images?.servHome,
        title: "QA and Testing",
        desc: "Comprehensive quality assurance and testing services to ensure software reliability and performance.",
    },
];

export const Blogs = [
    {
        id: 1,
        back: Images?.Blog1,
        title: "5 Free Software to Scale Your Business",
        type: "BUISNESS",
        desc: "These free software tools offer powerful features that can help you manage your projects, communicate effectively, build customer relationships, create stunning visuals, and streamline your billing processes. By leveraging these tools, you can scale your business efficiently without breaking the bank. Start integrating them into your workflow today and watch your business grow!",
        content: <>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "400px", margin: " 0  0 0 20px", float: "right" }, flexShrink: "0" }}>
                <img src={Images?.Blog1} alt="" style={{
                    width: "100%",
                    border: "2px solid var(--primary)"
                }} />
            </Box>
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                These free software tools offer powerful features that can help you manage your projects, communicate effectively, build customer relationships, create stunning visuals, and streamline your billing processes. By leveraging these tools, you can scale your business efficiently without breaking the bank. Start integrating them into your workflow today and watch your business grow!
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                Scaling a business can be challenging, but the right tools can make all the difference. Here are five free software solutions that can help you streamline operations, improve productivity, and scale your business effectively.
            </Typography>
            <br />
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>1. Trello</span>
                <br />
                <br />
                Trello is a powerful project management tool that uses boards, lists, and cards to help you organize tasks and collaborate with your team. It’s highly visual and intuitive, making it easy to manage projects, track progress, and stay on top of deadlines.
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "22px", fontWeight: "500" }}>Key Features:</span>
                <br />
                <br />
                <ul>
                    <li>Kanban-style boards for task management</li>
                    <li>Integration with other tools like Slack, Google Drive, and more</li>
                    <li>Customizable workflows to fit your business needs</li>
                    <li>Mobile app for on-the-go project management</li>
                </ul>
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>2. Slack</span>
                <br />
                <br />
                Slack is a communication platform that brings your team together in one place, making it easier to collaborate and stay connected. With channels for different projects or departments, you can keep conversations organized and ensure everyone is on the same page.
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "22px", fontWeight: "500" }}>Key Features:</span>
                <br />
                <br />
                <ul>
                    <li>Organized channels for different topics</li>
                    <li>Direct messaging and group chats</li>
                    <li>File sharing and integration with other tools</li>
                    <li>Powerful search functionality to find information quickly</li>
                </ul>
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>3. HubSpot CRM</span>
                <br />
                <br />
                HubSpot CRM is a robust customer relationship management tool that helps you manage your contacts, track deals, and grow your customer base. It’s free, easy to use, and scales with your business.
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "22px", fontWeight: "500" }}>Key Features:</span>
                <br />
                <br />
                <ul>
                    <li>Contact management and deal tracking</li>
                    <li>Email tracking and notifications</li>
                    <li>Meeting scheduling and live chat</li>
                    <li>Integration with email, social media, and other tools</li>
                </ul>
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>4. Canva</span>
                <br />
                <br />
                Canva is a graphic design tool that enables you to create stunning visuals for your business. Whether you need social media graphics, presentations, or marketing materials, Canva offers a wide range of templates and design elements to get you started.
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "22px", fontWeight: "500" }}>Key Features:</span>
                <br />
                <br />
                <ul>
                    <li>Drag-and-drop design interface</li>
                    <li>Thousands of templates and design elements</li>
                    <li>Collaboration features for team projects</li>
                    <li>Access to a library of free photos, icons, and fonts</li>
                </ul>
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>5. Zoho Invoice</span>
                <br />
                <br />
                Zoho Invoice is a free invoicing software that helps you create professional invoices, track payments, and manage expenses. It’s perfect for small businesses looking to streamline their billing processes and get paid faster.
            </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "22px", fontWeight: "500" }}>Key Features:</span>
                <br />
                <br />
                <ul>
                    <li>Customizable invoice templates</li>
                    <li>Expense tracking and time tracking</li>
                    <li>Automated payment reminders</li>
                    <li>Detailed reports and analytics</li>
                </ul>
            </Typography>
        </>
    },
    {
        id: 2,
        back: Images?.Blog2,
        title: "Should I invest in a Website?",
        type: "DIGITAL MARKETING",
        desc: "Investing in a website is a smart decision for any business. It establishes credibility, expands your reach, showcases your expertise, helps clients get to know you better, improves customer service, boosts marketing efforts, attracts new leads, provides valuable insights, and helps you stay competitive. In the long run, a website can be a powerful asset that drives growth and success. If you haven't yet invested in a website, now is the time to take the leap and establish your online presence.",
        content: <>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "400px", margin: " 0  0 0 20px", float: "right" }, flexShrink: "0" }}>
                <img src={Images?.Blog2} alt="" style={{
                    width: "100%",
                    border: "2px solid var(--primary)"
                }} />
            </Box>
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                In today's digital age, having an online presence is more important than ever. If you're a business owner, you might be wondering if investing in a website is worth the time and money. Here are several compelling reasons why a website can be a valuable investment for your business.
            </Typography>
            <br />
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>1. Establish Credibility</span>
                <br />
                <br />
                A professional website helps establish your business’s credibility. Potential customers are more likely to trust a company with an online presence. Your website serves as a digital storefront, providing visitors with information about your products, services, and values. Without a website, you risk appearing outdated or unreliable.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>2. Reach a Wider Audience</span>
                <br />
                <br />
                A website allows you to reach a global audience. Unlike a physical store that is limited by geographical boundaries, a website is accessible to anyone with an internet connection. This means you can attract customers from different locations, potentially expanding your market and increasing sales.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>3. Showcase Your Products and Services</span>
                <br />
                <br />
                Having a well-designed website allows you to showcase your expertise and authority in your industry. Through blog posts, case studies, and industry news, you can demonstrate your knowledge and position your business as a leader. This helps build trust with potential customers and differentiates you from competitors.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>4. Make Your Clients Get to Know You Better</span>
                <br />
                <br />
                Your website offers a platform for clients to learn more about your business. By sharing your company’s story, mission, and values, you create a personal connection with your audience. Detailed team bios, behind-the-scenes content, and customer testimonials help clients feel more connected and informed about who you are and what you do.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>5. Serve Your Clients Better</span>
                <br />
                <br />
                A website can significantly enhance your customer service. By providing FAQs, product guides, and contact forms, you make it easy for customers to find the information they need and reach out for support. This can lead to higher customer satisfaction and loyalty. Additionally, features like live chat and customer portals can improve service and communication.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>6. Boost Marketing Efforts</span>
                <br />
                <br />
                A website is a powerful marketing tool. It enables you to implement various digital marketing strategies such as search engine optimization (SEO), content marketing, and social media integration. By driving traffic to your website, you can generate leads and convert them into customers.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>7. Attract New Leads and Prospects</span>
                <br />
                <br />
                A well-optimized website can attract new leads and prospects. By appearing in search engine results and leveraging online advertising, you can reach potential customers who are actively searching for products or services like yours. Landing pages, lead magnets, and calls to action can help convert website visitors into leads and ultimately into paying customers.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>8. Gain Insights Through Analytics</span>
                <br />
                <br />
                Web analytics tools allow you to track and analyze your website’s performance. You can gather data on visitor behavior, traffic sources, and conversion rates. This information can help you make informed decisions about your marketing strategies and improve your business operations.
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <span style={{ fontSize: "25px", fontWeight: "500" }}>9. Stay Competitive</span>
                <br />
                <br />
                Many of your competitors likely already have websites. To stay competitive, you need to meet or exceed the standards set by others in your industry. A well-designed, functional website can give you a competitive edge and position your business as a leader in your field.  </Typography>
            <br />
            <br />
            <Typography
                variant={"p"}
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "15px", md: "18px" },
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                Investing in a website is a smart decision for any business. It establishes credibility, expands your reach, showcases your expertise, helps clients get to know you better, improves customer service, boosts marketing efforts, attracts new leads, provides valuable insights, and helps you stay competitive. In the long run, a website can be a powerful asset that drives growth and success. If you haven't yet invested in a website, now is the time to take the leap and establish your online presence.
            </Typography>
        </>
    },
    {
        id: 3,
        back: Images?.Blog3,
        title: "Building a Strong Online Presence: A Comprehensive Guide",
        type: "DIGITAL MARKETING",
        desc: "In today’s digital age, having a robust online presence is crucial for any business looking to succeed. Your online presence not only helps you reach a broader audience but also builds credibility and fosters trust with potential customers. Here’s a comprehensive step-by-step guide to help your business build and maintain a strong online presence.",
        content:
            <>
                <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "400px", margin: "0 0 0 20px", float: "right" }, flexShrink: "0" }}>
                    <img src={Images?.Blog3} alt="Online Presence" style={{ width: "100%", border: "2px solid var(--primary)" }} />
                </Box>
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    In today’s digital age, having a robust online presence is crucial for any business looking to succeed. Your online presence not only helps you reach a broader audience but also builds credibility and fosters trust with potential customers. Here’s a comprehensive step-by-step guide to help your business build and maintain a strong online presence.
                </Typography>
                <br />
                <br />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>1. Define Your Brand Identity</span>
                    <br />
                    <br />
                    <b>Step 1: Clarify Your Brand Values and Mission</b>
                    <br />
                    Identify what your business stands for and what it aims to achieve. This helps in creating a consistent brand message.
                    <br />
                    <br />
                    <b>Step 2: Create a Unique Brand Voice</b>
                    <br />
                    Develop a tone and style that reflects your brand’s personality. Whether it's professional, friendly, or quirky, consistency is key.
                    <br />
                    <br />
                    <b>Step 3: Design a Memorable Logo and Visual Identity</b>
                    <br />
                    Your logo and visual elements should be easily recognizable and reflect your brand’s essence.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>2. Develop a Professional Website</span>
                    <br />
                    <br />
                    <b>Step 1: Choose the Right Domain Name</b>
                    <br />
                    Your domain name should be easy to remember, relevant to your business, and reflective of your brand.
                    <br />
                    <br />
                    <b>Step 2: Opt for a Reliable Web Hosting Service</b>
                    <br />
                    Ensure your website is fast, secure, and has minimal downtime by choosing a reputable hosting provider.
                    <br />
                    <br />
                    <b>Step 3: Focus on User Experience (UX)</b>
                    <br />
                    Design your website to be user-friendly with easy navigation, quick load times, and mobile responsiveness.
                    <br />
                    <br />
                    <b>Step 4: Optimize for Search Engines (SEO)</b>
                    <br />
                    Implement SEO best practices such as using relevant keywords, creating high-quality content, and optimizing meta tags to improve your website’s visibility on search engines.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>3. Leverage Social Media Platforms</span>
                    <br />
                    <br />
                    <b>Step 1: Identify the Right Platforms</b>
                    <br />
                    Choose social media platforms where your target audience is most active (e.g., Facebook, Instagram, LinkedIn, Twitter).
                    <br />
                    <br />
                    <b>Step 2: Create and Curate Engaging Content</b>
                    <br />
                    Share a mix of content, including blog posts, videos, infographics, and user-generated content, to keep your audience engaged.
                    <br />
                    <br />
                    <b>Step 3: Interact with Your Audience</b>
                    <br />
                    Respond to comments, messages, and reviews promptly to build relationships and show that you value customer feedback.
                    <br />
                    <br />
                    <b>Step 4: Use Analytics Tools</b>
                    <br />
                    Monitor your social media performance using analytics tools to understand what works and refine your strategy.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>4. Implement Content Marketing</span>
                    <br />
                    <br />
                    <b>Step 1: Start a Blog</b>
                    <br />
                    Regularly publish high-quality, relevant content that addresses the needs and interests of your audience.
                    <br />
                    <br />
                    <b>Step 2: Create a Content Calendar</b>
                    <br />
                    Plan and schedule your content in advance to ensure consistency and relevance.
                    <br />
                    <br />
                    <b>Step 3: Utilize Different Content Formats</b>
                    <br />
                    Experiment with various content types such as articles, videos, podcasts, and infographics to cater to different audience preferences.
                    <br />
                    <br />
                    <b>Step 4: Promote Your Content</b>
                    <br />
                    Share your content across your website, social media channels, and email newsletters to reach a wider audience.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>5. Engage in Email Marketing</span>
                    <br />
                    <br />
                    <b>Step 1: Build an Email List</b>
                    <br />
                    Collect email addresses through website sign-ups, social media, and events. Offer incentives like eBooks or discounts to encourage sign-ups.
                    <br />
                    <br />
                    <b>Step 2: Create Valuable Email Campaigns</b>
                    <br />
                    Send regular newsletters, promotional offers, and personalized content to keep your subscribers engaged and informed.
                    <br />
                    <br />
                    <b>Step 3: Segment Your Audience</b>
                    <br />
                    Divide your email list into segments based on demographics, purchase history, and behavior to send targeted and relevant messages.
                    <br />
                    <br />
                    <b>Step 4: Analyze and Optimize</b>
                    <br />
                    Use email marketing analytics to track open rates, click-through rates, and conversions. Adjust your strategy based on the data to improve performance.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>6. Utilize Online Advertising</span>
                    <br />
                    <br />
                    <b>Step 1: Choose the Right Advertising Platforms</b>
                    <br />
                    Depending on your target audience, choose platforms like Google Ads, Facebook Ads, or LinkedIn Ads.
                    <br />
                    <br />
                    <b>Step 2: Create Compelling Ad Content</b>
                    <br />
                    Design eye-catching ads with clear calls to action to attract clicks and conversions.
                    <br />
                    <br />
                    <b>Step 3: Set a Budget and Monitor Performance</b>
                    <br />
                    Allocate a budget for your ad campaigns and regularly monitor their performance to ensure you’re getting a good return on investment.
                    <br />
                    <br />
                    <b>Step 4: Retarget Visitors</b>
                    <br />
                    Use retargeting ads to reach visitors who have previously interacted with your website but haven’t yet converted.
                </Typography>
                <br />
                <br />
                <hr />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    <span style={{ fontSize: "25px", fontWeight: "500" }}>7. Monitor and Adjust Your Strategy</span>
                    <br />
                    <br />
                    <b>Step 1: Use Web Analytics Tools</b>
                    <br />
                    Tools like Google Analytics can provide insights into your website’s performance, visitor behavior, and traffic sources.
                    <br />
                    <br />
                    <b>Step 2: Track Social Media Metrics</b>
                    <br />
                    Monitor engagement, reach, and conversions on your social media platforms to understand what content resonates with your audience.
                    <br />
                    <br />
                    <b>Step 3: Gather Customer Feedback</b>
                    <br />
                    Regularly ask for feedback from your customers to understand their needs and preferences. Use this information to improve your online presence.
                    <br />
                    <br />
                    <b>Step 4: Continuously Optimize</b>
                    <br />
                    SEO is an ongoing process. Regularly review your analytics and adjust your strategy based on the data to continually improve your rankings.
                </Typography>
                <br />
                <br />
                <Typography
                    variant={"p"}
                    sx={{ fontWeight: "200", fontSize: { xs: "15px", md: "18px" }, width: "100%", marginTop: "10px" }}
                >
                    By following these steps, businesses can build a strong online presence that not only attracts customers but also establishes credibility and fosters long-term growth. Remember, consistency and ongoing optimization are key to maintaining and enhancing your online presence.
                </Typography>
            </>
    }
];

export const Projects = [
    {
        back: Images?.codeCover,
        full: Images?.codeFull,
        title: "Code Challenge Championship",
        type: "Web Application Development",
        desc: "Code Challenge Championship is a flagship project where we built a global competition platform and community for students aged 5 to 24 to showcase their coding skills. This web application provides an engaging environment for young coders to participate in challenges, compete globally, and unlock their full potential."
    },
    {
        back: Images?.LMSCover,
        full: Images?.LMSFull,
        title: "Schools System (SkillCode)",
        type: "Custom Software Development",
        desc: "Developed a robust Learning Management System (LMS) for Skill Code Company, aimed at streamlining school, teacher, and student management processes. Our custom software solution includes detailed dashboards, class scheduling, session planning, feedback mechanisms, and performance tracking features for parents. It provides an intuitive platform for effective communication and efficient management within educational institutions."
    },
    {
        back: Images?.skillCover,
        full: Images?.skillFull,
        title: "Skill Code",
        type: "Web Application Development",
        desc: "Pulsonic proudly developed Skill Code's website, pioneering STEAM education to cultivate adaptable thinkers. We empower learners with creativity, critical thinking, and technical skills for the 21st century, driving positive change and igniting a passion for lifelong learning."
    },
    {
        back: Images?.wedoCover,
        full: Images?.wedoFull,
        title: "We Do Challenge",
        type: "Web Application Development",
        desc: "Our team spearheaded the development of the We Do Challenge website, a unique global competition igniting a passion for STEAM in young minds aged 4 to 10 through real-world problem-solving. By providing engaging challenges and fostering collaboration, we aim to inspire the next generation of innovators and problem solvers, driving positive change and shaping a future fueled by creativity and ingenuity."
    },
    {
        back: Images?.robotFull,
        full: Images?.robotFull,
        title: "International Robot Challenge",
        type: "Web Application Development",
        desc: "Pulsonic proudly developed a flagship website for International Robot Challenge, a prestigious competition for self-made, autonomous robots. Despite pandemic challenges, our website supported successful events from 2020 till now, contributing to the competition's growth and impact."
    },
    {
        back: Images?.regFull,
        full: Images?.regFull,
        title: "Registartion & Payment System",
        type: "Custom Software Development",
        desc: "Designed and developed a comprehensive Registration System, facilitating seamless management of participant details and providing a secure payment gateway for registration fees. Our solution includes a user-friendly portal for organizations to efficiently enter team details, simplifying the registration process and enhancing the overall experience for participants and organizers."
    },
];

export const Steps = [
    {
        title: "Discover Your Needs",
        desc: "We begin by understanding your business goals and challenges to tailor a solution that fits perfectly.",
    },
    {
        title: "Design the Solution",
        desc: "Our team of experts crafts a detailed plan and design, ensuring a seamless user experience and robust functionality.",
    },
    {
        title: "Develop the Product",
        desc: "We bring the design to life through agile development, creating a responsive, scalable, and secure application.",
    },
    {
        title: "Deliver and Support",
        desc: "After rigorous testing, we deliver the final product and provide ongoing support to ensure your success.",
    },
];

export const FAQs = [
    {
        question: "What services does Pulsonic offer?",
        answer: "We specialize in web application development, custom software development, and providing comprehensive IT solutions tailored to meet your business needs. Our services include website design, e-commerce solutions, mobile app development, and more.",
    },
    {
        question: "How can I get a quote for my project?",
        answer: "To get a quote for your project, simply fill out the contact form on our website with details about your requirements. One of our team members will review your information and get back to you with a personalized quote.",
    },
    {
        question: "What is the typical timeline for a project?",
        answer: "The timeline for a project varies depending on its complexity and scope. Once we understand your specific needs, we can provide an estimated timeline. Generally, smaller projects may take a few weeks, while larger, more complex projects could take several months.",
    },
    {
        question: "Can you help with website maintenance and updates?",
        answer: "Yes, we offer ongoing website maintenance and support services. Whether you need regular updates, security patches, or new features added, our team is here to ensure your website runs smoothly and stays up-to-date.",
    },
    {
        question: "Can you integrate third-party services and APIs into my website?",
        answer: "Absolutely! We have extensive experience integrating various third-party services and APIs to enhance the functionality of your website. Whether it's payment gateways, social media platforms, or CRM systems, we can seamlessly integrate them into your site.",
    },
    {
        question: "What if I need support after my project is completed?",
        answer: "We offer post-launch support and maintenance services to ensure your website or application continues to perform optimally. Whether you need technical support, updates, or enhancements, our team is available to assist you.",
    },
    {
        question: "How can I contact Pulsonic for more information?",
        answer: "You can contact us through our website’s contact form, email us at pulsonic.company@gmail.com, or call us at +201559661620. We are here to answer any questions you may have and provide the assistance you need.",
    },
];