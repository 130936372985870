import React, { useEffect } from "react";
import BlogTemplate from "../../Templates/Blog/Blog.template";

const BlogPage = () => {
  useEffect(() => {
    document.title = "Blog - Pulsonic";
  }, []);
  return <BlogTemplate />;
};

export default BlogPage;
