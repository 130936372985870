import React, { useEffect } from "react";
import AboutTemplate from "../../Templates/About/About.template";
import ContactTemplate from "../../Templates/Contact/Contact.template";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Contact - Pulsonic";
  }, []);
  return <ContactTemplate />;
};

export default ContactPage;
