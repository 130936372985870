import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Contact.css";
import { Images } from "../../Helpers/Images";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactForm from "../../Components/Molecules/ContactForm/ContactForm";
import { FAQs } from "../../Helpers/Arrays";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const ContactTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              fontSize: { xs: "20px", md: "35px" },
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Contact Us</span>
            <br />
            We'd Love To Hear<br /> From You..
          </Typography>
          <Typography
            variant={"p"}
            sx={{
              fontWeight: "200",
              fontSize: { xs: "15px", md: "15px" },
              width: "100%",
              maxWidth: "400px"
            }}
          >
            We’re here to help you bring your software dreams to life. Reach out to us for any inquiries, project discussions, or support needs. Our team is ready to assist you.
          </Typography>
        </Box>
        <img src={Images.Contact} alt="" style={{ width: "100%", maxWidth: "900px", margin: "auto", boxShadow: "0 -30px #03624c", marginTop: "50px" }} />
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 60px 50px", lg: "0 120px 50px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center", marginBottom: "30px",
          }}
        >
          It's Very Easy To Contact Us
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "850px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", md: "400px" }, backgroundColor: "#03624c10", padding: { xs: "30px", md: "50px" }, marginBottom: { xs: "20px", md: "0" } }}>
              <ContactForm />
            </Box>
            <Box sx={{
              width: "100%", maxWidth: "350px", margin: "0 20px"
            }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <PhoneIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>+20 1559661620</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <EmailIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>pulsonic.company@gmail.com</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>Pulsonic Company</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 60px 50px", lg: "0 120px 50px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column" },
          }}>
            <Box sx={{
              width: "100%"
            }}>
              <Typography
                variant={"h2"}
                sx={{
                  fontWeight: "600",
                  color: "black",
                  fontSize: { xs: "25px", md: "40px" },
                  textAlign: "start", marginBottom: "10px",
                }}
              >
                <span style={{ color: "var(--primary)", fontSize: "20px" }}>*FAQ</span>
                <br />
                Most Asked Questions
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "200",
                  fontSize: { xs: "12px", md: "15px" },
                  display: "block",
                  marginBottom: "20px"
                }}
              >
                Have more questions? Feel free to reach out to us directly, and our team will be happy to assist you!
              </Typography>
            </Box>
            <Box sx={{ width: "100%", backgroundColor: "#03624c10", padding: { xs: "20px", sm: "50px" } }}>
              {FAQs?.map((item) => (
                <Accordion sx={{
                  backgroundColor: "#03624c02", boxShadow: "0", borderBottom: "2px solid var(--primary)", borderTop: "0", borderRadius: "0 !important",
                  '&:before': {
                    height: "0",
                  }
                }}>
                  <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    َ{item?.question}
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.answer}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactTemplate;
