import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../Helpers/Images";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PrimaryButton from "../../Components/Atoms/Primary/PrimaryButton";
import "./Home.css";
import { height, margin, padding, width } from "@mui/system";
import ServiceCard from "../../Components/Molecules/ServiceCard/ServiceCard";
import ProjectCard from "../../Components/Molecules/ProjectCard/ProjectCard";
import TextFieldInput from "../../Components/Atoms/TextField/TextFieldInput";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BlogCard from "../../Components/Molecules/BlogCard/BlogCard";
import { Blogs, Projects, Services, Steps } from "../../Helpers/Arrays";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import ContactForm from "../../Components/Molecules/ContactForm/ContactForm";

const HomeTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ backgroundImage: `url(${Images.Background})`, height: { xs: "400px", md: "600px" }, backgroundSize: "cover", backgroundPosition: "center top" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontWeight: "800",
              color: "var(--primary)",
              fontSize: { xs: "35px", md: "70px" },
              marginTop: "30px"
            }}
          >
            Pulse your Dream <br />With Pulsonic Solutions
          </Typography>
          <PrimaryButton
            classBtn={"center"}
            text={<>Get Started <KeyboardDoubleArrowRightIcon style={{ fontSize: "25px", marginLeft: "4px" }} /></>}
            sx={{
              textTransform: "capitalize",
              borderRadius: "25px",
              backgroundColor: "var(--primary)",
              color: "white",
              padding: { xs: "7px 15px 7px 20px", md: "7px 25px 7px 33px" },
              width: "fit-content",
              marginTop: "20px",
              marginBottom: "30px",
              fontSize: { xs: "15px", md: "17px" },
              '&:hover': {
                backgroundColor: "var(--primary)",
              }
            }}
            click={() => navigate(`/contact`)}
          />
        </Box>
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "20px 0 0", sm: "50px 0 0", lg: "50px 0 0" },
          display: "flex",
          flexDirection: "column"
        }}>
        <div className="marquee-div" style={{ animationDuration: "17s" }}>
          <h2 className="StrokeLarge marquee">SERVICES FOR YOUR BUSINESS.</h2>
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "0px auto",
            alignItems: { xs: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "calc(100% - 30px)", sm: "35%" }, marginLeft: "-30px" }}>
            <img src={Images.servHome} alt="services" style={{ width: "100%", boxShadow: "30px 0 #03624c", marginBottom: "20px" }} />
          </Box>
          <Box sx={{
            width: { xs: "100%", sm: "50%" },
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: { xs: "flex-start" },
            flexDirection: "column",
            marginTop: { xs: "10px", sm: "0" }
          }}>
            <Typography
              variant={"h2"}
              sx={{
                fontWeight: "800",
                fontSize: { xs: "25px", md: "32px" },
              }}
            >
              <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Innovative IT Solutions for Your Business!</span>
              <br />
              Creative Solutions <br /> To Expand Your Business
            </Typography>
            <Box sx={{ margin: { xs: "0 0 10px", sm: "20px 0" }, display: "flex" }}>
              <Box>
                {/* <Typography
                  variant={"h4"}
                  sx={{
                    fontWeight: "800",
                    fontSize: { xs: "20px", md: "30px" },
                  }}
                  className="grText"
                >
                  70+
                </Typography> */}
                <Typography
                  variant={"p"}
                  sx={{
                    fontSize: { xs: "13px", md: "15px" },
                  }}
                >
                  Pulsonic is your trusted partner in delivering cutting-edge IT solutions designed to propel your business forward. Our expertise spans a variety of custom software services tailored to meet your unique needs.
                  Join the many businesses who trust Pulsonic to transform their ideas into impactful solutions.
                </Typography>
              </Box>
            </Box>
            <PrimaryButton
              classBtn={"center"}
              text={<>Start now <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
              sx={{
                backgroundColor: "var(--primary)",
                color: "white",
                textTransform: "capitalize",
                borderRadius: "20px",
                padding: "7px 15px 7px 20px",
                width: "fit-content",
                margin: { xs: "auto", sm: "0" },
                '&:hover': {
                  backgroundColor: "var(--primary)",
                }
              }}
              click={() => navigate(`/contact`)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "0 0 20px", sm: "0 0 50px", lg: "0 0 50px" },
          display: "flex",
          flexDirection: "column",
        }}>
        <div className="marquee-div" style={{ animationDuration: "20s" }}>
          <h2 className="StrokeLarge marquee">CHOOSE THE BEST PULSONIC SOLUTION.</h2>
        </div>
      </Box>
      <Box sx={{
        background: `linear-gradient(75deg, #03624c60,#00000090, #942D2D60), #000000ee`
      }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "20px 20px 50px", sm: "50px 60px 80px", lg: "50px 120px 80px" },
            maxWidth: "1400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              color: "white",
              fontSize: { xs: "25px", md: "50px" },
              textAlign: "center"
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Working Process</span>
            <br />
            Let's See How We Work!
          </Typography>
          <Grid
            container
            style={{
              justifyContent: "center",
              marginTop: "20px",
              maxWidth: "920px",
              margin: "auto",
              alignItems: "flex-start",
            }}
          >
            {Steps?.map((ser, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Box sx={{ position: "relative" }}>
                  <span style={{ fontSize: "80px", fontWeight: "800", position: "absolute", top: "-40px", left: "15px" }} className="grText1">0{i + 1}</span>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontWeight: "800",
                      color: "white",
                      fontSize: { xs: "17px", md: "20px" },
                      marginTop: "40px",
                      position: "relative",
                      left: "40px",
                      maxWidth: "80%",
                    }}
                  >
                    {ser?.title}
                  </Typography>
                  <Typography
                    variant={"p"}
                    sx={{
                      fontWeight: "200",
                      color: "#ffffffcc",
                      fontSize: { xs: "12px", md: "13px" },
                      position: "relative",
                      left: "40px",
                      display: "block",
                      maxWidth: "80%", marginTop: "8px"
                    }}
                  >
                    {ser?.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
            marginBottom: "20px"
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              fontSize: { xs: "20px", md: "32px" },
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Our Services</span>
            <br />
            We Provide Some Exclusive<br /> Serviecs For Clients
          </Typography>
          <PrimaryButton
            classBtn={"center"}
            text={<>View All <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
            sx={{
              border: "var(--primary) 2px solid",
              color: "var(--primary)",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "7px 15px 7px 20px",
              width: "fit-content",
              margin: { xs: "5px auto", sm: "0" },
            }}
            click={() => navigate(`services`)}
          />
        </Box>

        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "920px",
            margin: "auto",
            alignItems: "flex-start",
          }}
        >
          {Services?.map((ser) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <ServiceCard hover={true} title={ser?.title} back={ser?.back} desc={ser?.desc} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "0 0 20px", sm: "0 0 50px", lg: "0 0 50px" },
          display: "flex",
          flexDirection: "column",
        }}>
        <div className="marquee-div" style={{ animationDuration: "20s" }}>
          <h2 className="StrokeLarge marquee">CHOOSE THE BEST PULSONIC SOLUTION.</h2>
        </div>
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 60px 50px", lg: "0 120px 50px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center"
          }}
        >
          <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Some Of Our Work</span>
          <br />
          Case Study
        </Typography>
        <Box sx={{
          maxWidth: "900px", margin: 'auto', width: "100%"
        }}>
          <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            showIndicators={false}
            infiniteLoop={true}
            autoPlay={true}
            centerMode={isMobile ? false : true}
            centerSlidePercentage={50}
            renderArrowPrev={(onClickHandler) => (
              <KeyboardDoubleArrowLeftIcon onClick={onClickHandler} sx={{ cursor: "pointer", fontSize: "45px" }} />
            )}
            renderArrowNext={(onClickHandler) => (
              <KeyboardDoubleArrowRightIcon onClick={onClickHandler} sx={{ cursor: "pointer", fontSize: "45px", position: "absolute", top: '0', right: "0" }} />
            )}
          >
            {Projects?.slice(0, 4)?.map((ser) => (
              <ProjectCard title={ser?.title} back={ser?.back} desc={ser?.type} />
            ))}
          </Carousel>
        </Box>
        {/* <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "920px",
            margin: "10px auto 0",
            alignItems: "flex-start",
          }}
        >
          {Projects?.slice(0, 4)?.map((ser) => (
            <Grid
              item
              xs={12}
              sm={6}
            >
              <ProjectCard title={ser?.title} back={ser?.back} desc={ser?.type} />
            </Grid>
          ))}
        </Grid> */}
        <PrimaryButton
          classBtn={"center"}
          text={<>View All <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
          sx={{
            border: "var(--primary) 2px solid",
            color: "var(--primary)",
            textTransform: "capitalize",
            borderRadius: "20px",
            padding: "7px 15px 7px 20px",
            width: "fit-content",
            margin: "20px auto 0"
          }}
          click={() => navigate(`/portfolio`)}
        />
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "0 0 20px", sm: "0 0 50px", lg: "0 0 50px" },
          display: "flex",
          flexDirection: "column",
        }}>
        <div className="marquee-div" style={{ animationDuration: "20s" }}>
          <h2 className="StrokeLarge marquee">SOME OF OUR WORK FOR YOU.</h2>
        </div>
      </Box>
      <Box sx={{ background: `linear-gradient(75deg, #000000aa, #000000aa), url(${Images.ContBack})`, backgroundSize: "cover", backgroundPosition: "center center" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "40px 20px", sm: "70px 40px", lg: "70px 40px" },
            maxWidth: "900px",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              fontWeight: "800",
              color: "white",
              fontSize: { xs: "30px", md: "45px" },
            }}
          >
            Need Any Kind <br />Of Help/Support
          </Typography>
          <PrimaryButton
            classBtn={"center"}
            text={<>Contact Us <KeyboardDoubleArrowRightIcon style={{ fontSize: "25px", marginLeft: "4px" }} /></>}
            sx={{
              textTransform: "capitalize",
              borderRadius: "25px",
              backgroundColor: "white",
              color: "var(--primary)",
              padding: { xs: "7px 15px 7px 20px", md: "7px 25px 7px 33px" },
              width: "fit-content",
              fontSize: { xs: "15px", md: "17px" },
              marginTop: { xs: "15px", sm: "0" },
              height: "fit-content",
              '&:hover': {
                backgroundColor: "white",
              }
            }}
            click={() => navigate(`/contact`)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center", marginBottom: "30px",
          }}
        >
          <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Contact Us</span>
          <br />
          It's Very Easy To Contact Us
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "850px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", md: "400px" }, backgroundColor: "#03624c10", padding: { xs: "30px", md: "50px" }, marginBottom: { xs: "20px", md: "0" } }}>
              <ContactForm />
            </Box>
            <Box sx={{
              width: "100%", maxWidth: "350px", margin: "0 20px"
            }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <PhoneIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>+20 1559661620</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <EmailIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>pulsonic.company@gmail.com</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>Pulsonic Company</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "0 0 20px", sm: "0 0 50px", lg: "0 0 50px" },
          display: "flex",
          flexDirection: "column",
        }}>
        <div className="marquee-div" style={{ animationDuration: "20s" }}>
          <h2 className="StrokeLarge marquee">SOME OF OUR WORK FOR YOU.</h2>
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
            marginBottom: "20px"
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              fontSize: { xs: "20px", md: "32px" },
            }}
          >
            <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Our Blog</span>
            <br />
            Learn From Our Latest News
          </Typography>
          <PrimaryButton
            classBtn={"center"}
            text={<>View All <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
            sx={{
              border: "var(--primary) 2px solid",
              color: "var(--primary)",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "7px 15px 7px 20px",
              width: "fit-content",
              margin: { xs: "5px auto", sm: "0" },
            }}
            click={() => navigate(`blog`)}
          />
        </Box>

        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "920px",
            margin: "auto",
            alignItems: "flex-start",
          }}
        >
          {[...Blogs]?.reverse()?.slice(0, 3)?.map((ser) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <BlogCard title={ser?.title} back={ser?.back} desc={ser?.desc} type={ser?.type} id={ser?.id} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        className="marquee-section"
        sx={{
          width: "100%",
          padding: { xs: "0 0 20px", sm: "0 0 50px", lg: "0 0 50px" },
          display: "flex",
          flexDirection: "column",
        }}>
        <div className="marquee-div" style={{ animationDuration: "20s" }}>
          <h2 className="StrokeLarge marquee">LEARN FROM OUR LATEST BLOGS.</h2>
        </div>
      </Box>


      <Box
        sx={{
          width: "100%",
          padding: { xs: "0 20px 20px", sm: "0 50px 60px", lg: "0 50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            justifyContent: "center",
            maxWidth: "900px",
            margin: "0 auto",
            alignItems: "flex-start",
          }}
        >
          <Grid
            item
            xs={4}
            sm={3}
          >
            <Box sx={{ margin: "10px", display: "flex", justifyContent: "center" }}>
              <img src={Images?.code} alt="" style={{ width: "100%", maxWidth: "100px", margin: "auto" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={3}
          >
            <Box sx={{ margin: "10px", display: "flex", justifyContent: "center" }}>
              <img src={Images?.wedo} alt="" style={{ width: "100%", maxWidth: "110px", margin: "5px auto 0" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={3}
          >
            <Box sx={{ margin: "10px", display: "flex", justifyContent: "center" }}>
              <img src={Images?.skill} alt="" style={{ width: "100%", maxWidth: "110px", margin: "5px auto 0" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HomeTemplate;
