import React, { useEffect } from "react";
import HomeTemplate from "../../Templates/Home/Home.template";

const HomePage = () => {
  useEffect(() => {
    document.title = "Home - Pulsonic";
  }, []);
  return <HomeTemplate />;
};

export default HomePage;
