import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./About.css";
import PrimaryButton from "../../Components/Atoms/Primary/PrimaryButton";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Images } from "../../Helpers/Images";
import TextFieldInput from "../../Components/Atoms/TextField/TextFieldInput";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactForm from "../../Components/Molecules/ContactForm/ContactForm";

const AboutTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "900px",
            margin: "auto",
            alignItems: { xs: "flex-start", md: "flex-end" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontWeight: "800",
              fontSize: { xs: "25px", md: "35px" },
            }}
          >
            Build Your Dream Now..<br /> With <span style={{ color: "#03624c", textDecoration: "underline" }}>Pulsonic</span>
          </Typography>
          <Typography
            variant={"p"}
            sx={{
              fontWeight: "200",
              fontSize: { xs: "12px", md: "15px" },
              width: "100%",
              maxWidth: "350px",
              marginTop: { xs: "10px", md: "0" }
            }}
          >
            We offer some special sevices through which you can easliy complete the marketing work on social media. Which helps increase your lead and sales.
          </Typography>
        </Box>
        <img src={Images.AboutBackground} alt="" style={{ width: "100%", maxWidth: "900px", margin: "auto", boxShadow: "0 -30px #03624c", marginTop: "50px" }} />
      </Box>
      <Box sx={{
        background: `linear-gradient(75deg, #03624c60,#00000090, #942D2D60), #000000ee`
      }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "900px",
              margin: "auto",
              alignItems: { xs: "center", sm: "center" },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ paddingRight: "10px" }}>
              <Typography
                variant={"h2"}
                sx={{
                  fontWeight: "800",
                  color: "white",
                  fontSize: { xs: "25px", md: "35px" },
                  textAlign: "start",
                  maxWidth: { xs: "auto", sm: "350px" },
                }}
              >
                <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Our Vision</span>
                <br />
                Watch Our Vision To Findout More
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "200",
                  fontSize: { xs: "15px", md: "18px" },
                  color: "#ffffffcc", marginTop: "30px", display: "block",
                  fontSize: { xs: "12px", md: "18px" },
                  width: "100%",
                  maxWidth: "350px",
                  margin: { xs: "10px 0", md: "0" }
                }}
              >
                Pulsonic envisions a world where businesses achieve their highest potential through innovative software solutions. By harnessing the pulse of technology and the sonic speed of creativity, we transform dreams into reality, driving sustainable success and growth globally.
              </Typography>
            </Box>
            <img src={Images.Vision} alt="" style={{ width: "calc(100% - 30px)", maxWidth: "300px", boxShadow: "30px 0  #03624c", marginRight: "30px" }} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1400px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: { xs: "25px", md: "50px" },
            textAlign: "center", marginBottom: "30px",
          }}
        >
          <span style={{ color: "var(--primary)", fontSize: "20px" }}>*Contact Us</span>
          <br />
          Contact Us For Any Query
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "850px",
            margin: "auto",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{
            width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", md: "400px" }, backgroundColor: "#03624c10", padding: { xs: "30px", md: "50px" }, marginBottom: { xs: "20px", md: "0" } }}>
              <ContactForm />
            </Box>
            <Box sx={{
              width: "100%", maxWidth: "350px", margin: "0 20px"
            }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <PhoneIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>+20 1559661620</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <EmailIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>pulsonic.company@gmail.com</span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon color="primary" style={{ border: "2px solid var(--primary)", borderRadius: "50%", width: "50px", height: "50px", padding: "10px" }} />
                <span style={{ color: "var(--primary)", marginLeft: "15px" }}>Pulsonic Company</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutTemplate;
