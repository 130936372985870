import { Box, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PrimaryButton from "../../Atoms/Primary/PrimaryButton";
import "./NavBar.css";
import { Images } from "../../../Helpers/Images";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (bool) => {
    setDrawerOpen(bool);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "80px",
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        zIndex: "1000",
        boxShadow: "0px 0px 20px 2px rgba(0,0,0,0.1)",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        sx={{
          height: "80px",
          padding: { xs: "0 20px", sm: "0 60px", lg: "0 120px" },
          justifyContent: "space-between",
          maxWidth: "1400px",
          margin: "auto",
        }}
      >
        <Grid item xs={2} style={{ display: "flex", justifyContent: "start" }}>
          <img
            style={{ height: "35px", cursor: "pointer" }}
            src={Images.Logo}
            alt="Pulsonic Logo"
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            maxWidth: "600px",
            margin: "auto",
            display: { xs: "none !important", md: "flex !important" },
          }}
        >
          <Typography className="NavLinks" onClick={() => navigate("/")}>
            Home
          </Typography>
          <Typography className="NavLinks" onClick={() => navigate("/about")}>
            About
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => navigate("/services")}
          >
            Services
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => navigate("/portfolio")}
          >
            Portfolio
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => navigate("/blog")}
          >
            Blog
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => navigate("/contact")}
          >
            Contact
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "none !important", md: "flex !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <PrimaryButton
            classBtn={"center"}
            text={<>Let's Talk <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              backgroundColor: "var(--primary)",
              color: "white",
              padding: "7px 15px 7px 23px",
              width: "fit-content",
              marginBottom: "5px",
            }}
            click={() => navigate(`/contact`)}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "flex !important", md: "none !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <MenuIcon className="menuIcon" onClick={() => handleDrawer(true)} />
        </Grid>
      </Grid>
      <Drawer
        open={DrawerOpen}
        onClose={() => handleDrawer(false)}
        className="drawer"
      >
        <img
          style={{
            height: "30px",
            cursor: "pointer",
            width: "fit-content",
            margin: "40px 40px 20px",
          }}
          src={Images.Logo}
          alt="Pulsonic Logo"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        />
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        >
          Home
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/about");
            handleDrawer(false);
          }}
        >
          About
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/services");
            handleDrawer(false);
          }}
        >
          Services
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/portfolio");
            handleDrawer(false);
          }}
        >
          Portfolio
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/blog");
            handleDrawer(false);
          }}
        >
          Blog
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/contact");
            handleDrawer(false);
          }}
        >
          Contact
        </Typography>
        <PrimaryButton
          classBtn={"center"}
          text={<>Let's Talk <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
          style={{
            textTransform: "capitalize",
            borderRadius: "20px",
            backgroundColor: "var(--primary)",
            color: "white",
            padding: "7px 15px 7px 23px",
            width: "fit-content",
            margin: "20px auto",
          }}
          click={() => {
            navigate(`/contact`);
            handleDrawer(false);
          }}
        />
      </Drawer>
    </Box>
  );
};

export default NavBar;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
