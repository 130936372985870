import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../Helpers/Images";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import "./Footer.css";
import InstagramIcon from '@mui/icons-material/Instagram';
import PrimaryButton from "../../Atoms/Primary/PrimaryButton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#11141d",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "20px 60px", lg: "20px 120px" },
          maxWidth: "1400px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid #fff",
            marginTop: { xs: "50px", md: "80px" },
            marginBottom: { xs: "20px", md: "50px" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center"
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "25px",
              margin: "10px 0",
            }}
          >
            Pulse your Dream With Pulsonic Solutions
          </Typography>
          {/* <PrimaryButton
            classBtn={"center"}
            text={<>Contact Us <KeyboardDoubleArrowRightIcon style={{ fontSize: "20px", marginLeft: "4px" }} /></>}
            sx={{
              backgroundColor: "white",
              color: "var(--primary)",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "7px 15px 7px 20px",
              width: "fit-content",
              height: "fit-content",
              '&:hover': {
                backgroundColor: "white",
              }
            }}
            click={() => navigate(`services`)}
          /> */}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <img
              style={{ height: "40px", cursor: "pointer" }}
              src={Images.WhiteLogo}
              alt="Pulsonic Logo"
              onClick={() => navigate("/")}
            />
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "start",
                fontWeight: "200",
                color: "#B8B8B8",
                fontSize: "12px",
                margin: { xs: "10px 0", sm: "30px 0 10px" },
                maxWidth: { xs: "100%", sm: "250px" },
                display: { xs: "block", sm: "block" },
              }}
            >
              At Pulsonic, we envision a world where businesses achieve their highest potential through innovative software solutions. By harnessing the pulse of technology and the sonic speed of creativity, we transform dreams into reality, driving sustainable success and growth globally.
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "5px",
              minWidth: "150px"
            }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "20px",
                marginRight: "5px",
                marginBottom: "20px"
              }}
            >
              Company
            </Typography>
            <ul className="footerLinks">
              <li onClick={() => navigate("/blog")}>Blog</li>
              <li onClick={() => navigate("/portfolio")}>Portfolio</li>
              <li onClick={() => navigate("/services")}>Services</li>
              <li onClick={() => navigate("/contact")}>Contact</li>
              <li onClick={() => navigate("/about")}>About Pulsonic</li>
            </ul>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
              paddingTop: "5px", width: { xs: "100%", sm: "auto" }
            }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "20px",
                marginBottom: { xs: "10px", sm: "30px" },
              }}
            >
              Follow Us
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", minWidth: "240px", justifyContent: { xs: "center", sm: "flex-start" } }}>
              <a
                href="https://www.facebook.com/profile.php?id=61560420654285"
                target="_blank"
              >
                <FacebookIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://www.linkedin.com/company/pulsonic-io/"
                target="_blank"
              >
                <LinkedInIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://www.instagram.com/pulsonic_io/"
                target="_blank"
              >
                <InstagramIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://wa.me/+201559661620"
                target="_blank"
              >
                <WhatsAppIcon className="SocialFooterIcon" />
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: "30px",
            marginTop: "20px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center"
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "15px",
              marginTop: { xs: "5px", sm: "0" },
            }}
          >
            Pulsonic© 2024 All Rights reserved.
          </Typography>

          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "15px",
              marginTop: { xs: "5px", sm: "0" },
            }}
          >
            Terms & Conditions | Privacy Policy
          </Typography>
        </Box>
      </Box>
    </Box>

  );
};

export default Footer;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
