import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./TextFieldInput.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

function TextFieldInput({
  selected,
  type = "text",
  placeholder = "",
  className,
  change,
  error,
  StartAdornment = null,
  EndAdornment = null,
  Required = true,
  oldValue,
  setNewObject,
  name,
  newObject,
  blur,
  newValue,
  keyDown,
  searchIcon = "",
  searchAction,
  searchCloseAction,
  style,
  ...props
}) {
  const [value, setValue] = useState(newValue || "");
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  function handleChange(e) {
    setValue(e.target.value);
  }

  const handleAddAttribute = (e) => {
    let tempArray = Array.isArray(props.attribute) ? [...props.attribute] : [];
    tempArray.push(value);
    props.setAttribute(tempArray);
    setValue("");
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      select={selected}
      required={Required}
      style={style}
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      name={name}
      className={"textfield " + className}
      error={error ? true : false}
      placeholder={placeholder}
      onBlur={blur}
      onChange={change ?? handleChange}
      value={newValue ?? value}
      variant="outlined"
      onKeyDown={keyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <>{StartAdornment}</>
          </InputAdornment>
        ),
        endAdornment: searchIcon ? (
          <SearchIcon
            className="searchIcon"
            onClick={searchAction}
            onKeyDown={keyDown}
          />
        ) : (
          <InputAdornment position="end">
            <>
              {type === "password" ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ) : (
                <>
                  {" "}
                  {EndAdornment == null && <>{EndAdornment}</>}
                  {EndAdornment !== null ? (
                    <IconButton onClick={handleAddAttribute}>
                      {EndAdornment}
                    </IconButton>
                  ) : (
                    <>{EndAdornment}</>
                  )}
                  {EndAdornment == null && <>{EndAdornment} </>}
                </>
              )}
            </>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default TextFieldInput;
